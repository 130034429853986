.Container {
  height: 100%;
  width: 100%;
 
  align-items: center;


  font-family: sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.CoinContainer {
  position: relative;
  width: 20em;
  aspect-ratio: 1 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CoinContainerAnimate {
  animation: scale 2s linear 2 alternate;
}

.Coin {
  position: absolute;
  width: 11em;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  backface-visibility: hidden;
}

.Head {
  z-index: 1;
  animation: Hzind 300ms linear infinite forwards;
  background-color: brown;
}

.Tail {
  z-index: -1;
  animation: Tzind 300ms linear infinite forwards;
  background-color: red;
}

#Head {
  z-index: 1;
  background-color: red;
}

#Tail {
  z-index: -1;
  background-color: brown;
}

.Coin p {
  font-size: 1.5em;
  color: white;
}

.Button {
  height: 4em;
  width: 10em;
  margin-bottom: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-cust {
  height: 2em;
  width: 6em;
  font-size: 1.5em;
  outline: none;
  border: none;
  border-radius: 0.2em;
  cursor: pointer;
  color: rgb(255, 255, 255);
  background-color: rgb(54, 54, 54);
  transition: all 500ms ease;
}

.btn-cust:hover {
  background-color: rgb(29, 29, 29);
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}

@keyframes Tzind {
  0% {
    transform: rotateX(0deg);
    z-index: -1;
  }
  100% {
    transform: rotateX(180deg);
    z-index: 1;
  }
}

@keyframes Hzind {
  0% {
    z-index: 1;
    transform: rotateX(180deg);
  }
  100% {
    z-index: -1;
    transform: rotateX(0deg);
  }
}

.content-wrap {
  padding: 20px;
}

.row {
  margin: 0 -15px;
}

.col-xl-6, .col-lg-8, .col-lg-3, .col-xl-3, .col-lg-9, .col-xl-9 {
  padding: 0 15px;
}

.text-center {
  text-align: center;
}

.mb-5 {
  margin-bottom: 3rem;
}

.h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.fw-bold {
  font-weight: bold;
}

.p-5 {
  padding: 3rem;
}

.rounded-5 {
  border-radius: 1.25rem;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.info-box {
  height: auto;
}

.info-text {
  font-weight: 300;
  opacity: 0.7;
  color: #000;
  text-shadow: 0 -4px 20px rgba(0, 0, 0, 0.25);
}

.sign-up-text {
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
  color: #000;
  font-size: 20px;
  margin-top: -10px;
}

.feature-text {
  font-weight: 200;
  opacity: 0.5;
  color: #000;
  text-shadow: 0 -4px 20px rgba(0, 0, 0, 0.25);
  margin-top: -10px;
  font-size: 18px;
  text-align: center;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.button {
  border-radius: 3px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.dark {
  background-color: rgb(255, 255, 255);
}

.game-title {
  font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
  color: #000;
  font-size: 25px;
  margin-top: -10px;
}

.score-text {
  color: rgb(154, 154, 154);
  font-size: 18px;
}
